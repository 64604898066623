.footer-bg {
    background-color:  #8670ef;
  
}

.single-footer-widget {
    padding: 10px 0 90px;
    text-align: start;
}

.widget-title {
   
    margin-bottom: 30px;
}

.widget-title h2 {
    line-height: 1;
    font-size: 30px;
    color: white !important;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 600;
}
.footer-link {
    font-weight: 400;
    display: block;
    line-height: 34px;
    color:white;

}

.footer-link:hover {
    color: black !important; 
}

.footer-copy-right {
    padding: 20px 0;
    background-color:  #8670ef;
    color: white!important;
}

.developer {
    color:  #8670ef;
}


/* for mobile responsive */
@media only screen and (max-width: 426px){
    .single-footer-widget {
        padding: 45px 0 20px;
    }
}

@media only screen and (max-width: 321px){
    .footer-copy-right {
        padding: 20px 8px;
    }
}