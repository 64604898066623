@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

.container-head {
    background: #fff;
    border-radius: 10px;
    transition: all .5s ease-in-out;
 
    z-index:1;
}


.ms-auto{

}

.head-bg {
    background-color:#f9f9f9 !important;
    font-family: cursive;
}

.list-item {
    padding: 15px 25px 15px 25px;
    display: inline-block;
    color: #006;
    text-transform: capitalize;
   
    font-family: Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-family: Helvetica,Arial,sans-serif;
}

.list-item:hover {
    color:#8670ef ;
  
}
.list-item:focus {
    color:#8670ef ;
  
}
.navbar{
    background: aliceblue;
 box-shadow: 0 0 15px lightskyblue;
}

.ml-5 {
    margin-left: 20px !important;
}

.ml-5:hover {
    text-shadow: 0 0 15px #8670ef ;
    border-color: #8670ef  !important;
}

.userName {
    color: #8670ef;
    margin-left: 5px;
}

.btn {
     
    margin-left: 15px;
    margin-right: 15px;
}
.btn:active{
   
        color:#8670ef ;
      

}

.btn-danger {
    font-family: Raleway-SemiBold;
    font-size: 18px;
    font-weight: bold;
    color: #8670ef ;
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid #8670ef ;
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-danger:hover {
    color: #FFF;
    background:#8670ef  ;
    border: 2px solid #8670ef ;
  }



  /* for mobile responsive */

@media (max-width: 992px) {
    /* 2: Navbar */
    .btn {
        margin-left: 57px;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .container-head {
     
    }
}


@media (max-width: 426px) {
    .list-item {
        padding: 15px 0;
    }

    .btn {
        margin: 15px auto;
    }
}